/* import tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* import libs */
@import "~nprogress/nprogress.css";
@import "~react-toastify/dist/ReactToastify.css";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;900&display=swap");

@font-face {
  font-family: "Fasthand";
  src: url("../public/fonts/Fasthand-Regular.ttf");
}

body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Removes Powered by Google in Address autocomplete (additional fields) */
.pac-container:after {
  content: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

/* Scrollbar design */

/* width */
/* ::-webkit-scrollbar {
  width: 10px;
} */

/* Track */
/* ::-webkit-scrollbar-track {
  border-radius: 80vh;
  background: none;
} */

/* Handle */
/* ::-webkit-scrollbar-thumb {
  border-radius: 100vh;
} */

/* .signature-font {
  font-family: "Fasthand", sans-serif;
  font-size: 3rem;
} */

/* nprogress */
#nprogress {
  left: 0;
  top: 0;
  z-index: 9999;
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
}
#nprogress .bar {
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  height: 4px;
  width: 100%;
  background: #29d;
}
#nprogress .spinner {
  display: inline-flex;
  position: fixed;
  top: 50%;
  left: 0vw;
  width: 80px;
  height: 80px;
  margin: auto;
}

#nprogress .spinner .spinner-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid;
  border-color: #e4e4ed;
  border-right-color: #3537e8;
  animation: lds-dual-ring 0.3s infinite linear;
}

@keyframes lds-dual-ring {
  to {
    transform: rotate(1turn);
  }
}

/* Phone Number Field  */
.phone-input input {
  border: none !important;
  box-shadow: none !important;
}

/* slick slider overrides */
.slick-prev {
  left: -15px;
}
.slick-next {
  right: -15px;
}
.slick-prev,
.slick-next {
  top: 10%;
}
.slick-prev:before,
.slick-next:before {
  color: #000;
}

/* react-toastify */
:root {
  --toastify-toast-min-height: 48px;
  --toastify-color-success: #546ccc;
}

.Toastify__toast-icon {
  width: 14px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset; /* Change the color to your own background color */
}
